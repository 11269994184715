<template>
  <!-- Desc: config driven header component for entity details -->
  <div
    class="u-width-100 u-display-flex u-bg-color-grey-white u-font-size-3 u-spacing-mb-m u-bg-color-grey-white u-spacing-ph-l sticky u-header-box-shadow"
    style="z-index: 99"
  >
    <div
      v-if="entityConfig && entityId"
      class="u-display-flex u-width-100"
    >
      <loader
        class="fill--parent"
        :loading="metadata.status === 'loading'"
        :color="'#3684bb'"
      />
      <div class="u-display-flex u-flex-align-items-baseline u-spacing-pt-xxs">
        <div @click="redirectTo">
          <rb-icon
            icon="arrow_back"
            class="u-color-grey-lighter arrow--entity_details u-icon-size-2 u-cursor-pointer u-spacing-mr-s u-spacing-pt-xs"
          />
        </div>
        <div class="u-display-inline u-spacing-pt-xxs">
          <Bookmark
            v-if="entityConfig.bookmark.value"
            :title="
              entityConfig.bookmark.fromMetadata
                ? metadata.data[entityConfig.bookmark.title]
                : entityConfig.bookmark.title
            "
            :path="$route.fullPath"
          />
        </div>
      </div>
      <!-- line 1 & line 2 -->
      <div class="u-flex-direction-column u-width-100">
        <div
          v-for="(line, index) in lines"
          :key="index"
          class="u-display-flex u-width-100 u-flex-justify-content-space-between"
        >
          <div
            v-if="entityConfig[line] && entityConfig[line].items.length"
            :class="entityConfig[line].class"
            class="u-display-flex u-flex-wrap-yes"
          >
            <div
              v-for="(lineItem, lineIndex) in entityConfig[line].items"
              :key="`${lineIndex}_${line}`"
            >
              <attribute
                v-if="lineItem.itemType === 'attribute'"
                :line-item="lineItem"
                :line-index="lineIndex"
                :metadata="metadata.data"
              />
            </div>
          </div>
          <div
            v-if="index === 0"
            class="u-display-flex date-filter-conatiner"
          >
            <rb-filter-panel
              id="filterID"
              :key="filterKey"
              :data="filterData"
              :primary="primaryFilterData"
              :secondary-data="secondaryFilterData"
              :listener-event="filterConfig.filterLocalStorageKey"
              :config="filterConfig.config"
              :loader-status="loaderStatus"
              :emit="filterConfig.config.emit"
              :has-search="true"
              :new-date="true"
              :allow-date-compare="true"
              :enable-save="false"
              :page="filterConfig.page"
              :hide-filter="true"
              :hide-add-filter="true"
              default-date-post-range="last30Days"
              :min-max-date="minMaxDate"
              :enable-remove-all="false"
              :show-notification="false"
              :enable-open-filter="false"
              :additional-ranges="additionalRanges"
              @savedFilterApplied="filterKey++"
              @filterMounted="handleFilterMount"
            />
            <div
              v-if="
                entityConfig &&
                entityConfig.activityLog &&
                entityConfig.activityLog.show
              "
              class="u-spacing-pl-s"
            >
              <div
                class="activity-log-entity-details u-cursor-pointer u-spacing-ph-m u-spacing-pv-s u-border-all u-border-color-grey-xxx-light u-border-radius-s u-border-width-s"
                @click="openSidePanel"
              >
                <rb-icon
                  title="Activity log"
                  icon="timeline"
                  class="rb-icon--medium u-color-grey-lighter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';

import rbFilterPanel from '@/components/widgets/filter-panel.vue';
import widgetsMixin from '@/components/widgetsMixin';
import attribute from '@/pages/entity_details/common_components/header/attribute.vue';
import { eventBus } from '@/utils/services/eventBus';
import { deepReplaceObjectValues } from '@/pages/entity_details/common_components/helper.js';
import { commons } from '@/components/ams/campaigns/commons.js';
import moment from 'moment-timezone';

export default {
  components: {
    rbFilterPanel,
    loader,
    attribute
  },
  mixins: [widgetsMixin],

  props: {
    entityConfig: {
      default: null,
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      lines: ['line1', 'line2', 'line3'],
      filterKey: 0,
      minMaxDate: {
        minDate: moment().subtract(1, 'years').format('YYYY-MM-DD'),
        maxDate: moment().format('YYYY-MM-DD')
      },
      additionalRanges: commons.additionalDateRanges(),
      filterConfig: {}
    };
  },
  computed: {
    metadata() {
      return this.$store.getters[this.entityConfig.metadata_api_state.getter];
    }
  },

  created() {
    // hit apis here
    this.filterConfig = this.entityConfig.filters;
    console.log(this.entityConfig);
  },
  methods: {
    redirectTo() {
      const { config, value, path } = this.entityConfig.redirectTo;
      if (config && value) {
        const entityId = this.$route.params.entityId;
        deepReplaceObjectValues(config, ':entityId', entityId);
        this.$router.push(config);
      } else if (path) {
        this.$router.push(path);
      }
    },
    handleFilterMount() {
      this.$emit('filterMounted');
    },
    openSidePanel() {
      eventBus.$emit('entityOpenActivityLog', {
        name: this.entityConfig.pageName,
        params: {
          widget: this.entityConfig.widgetName,
          primaryKey: this.entityId
        }
      });
    }
  }
};
</script>
<style lang="css">
.arrow--entity_details {
  height: 21;
  width: 21;
}
.arrow--entity_details svg {
  height: 21;
  width: 21;
}
.activity-log-entity-details:hover,
.activity-log-entity-details:focus {
  border: 1px solid #caccce;
}
</style>
<style scoped>
.date-filter-conatiner {
  position: absolute;
  right: 20px;
}
.u-width-900px {
  width: 900px;
}
</style>
